import Swiper from 'swiper';
import Inputmask from 'inputmask';
import Plyr from 'plyr';
import Ellipsis from 'ftellipsis';
import 'core-js/features/global-this';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import AirDatepicker from 'air-datepicker';
// var openNav = () => {
//   $('#menu').addClass('show');
//   $('.obfuscator').addClass('show');
//   $('body').addClass('no-scroll');
// }

// var closeNav = () => {
//   $('#menu').removeClass('show');
//   $('.obfuscator').removeClass('show');
//   $('body').removeClass('no-scroll');
//   $('#service-menu').removeClass('show');
// }

// var normalizeMenu = () => {
//   if (window.innerWidth < 1132) return;
//   $('.obfuscator').removeClass('show');
//   $('#menu').removeClass('show');
//   $('body').removeClass('no-scroll');
//   $('#service-menu').removeClass('show');
// }

var collapseMenu = (elem) => {
  var dataTarget = elem.currentTarget.getAttribute('data-target')
  var dataButton = elem.currentTarget.getAttribute('data-button')
  document.querySelector(dataButton).classList.toggle('open');
  document.querySelector(dataTarget).classList.toggle('show');
} 

window.recaptchaInit = function() {
  var $el = $('.g-recaptcha')
  if ($el.length) {
    grecaptcha.render($el.get(0), { sitekey: '6LfvOi8iAAAAAFs4BSJaUoLbp5qLcuxk0cZT9pzr' })
  }
}

//window.addEventListener('resize', () => normalizeMenu() );
window.addEventListener('DOMContentLoaded', function() {
  $('.collapse').collapse('hide')
  var specializationId = $('.card.specializations').data('specid')
  if (specializationId) {
    var collapse = $('#spec-'+specializationId+ ' .spec_collapse')
    console.log(collapse)
    console.log('#spec-'+specializationId+ ' .collapse')
    collapse.collapse('show')
    collapse.get(0).scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  //$('#unavailableModal').modal('show');

  // $('#menuToggler').on('click', () => openNav());

  // $('.obfuscator').on('click', () => closeNav());

  // $('#menuCloser').on('click', (e) => {
  //   e.preventDefault();
  //   closeNav();
  // });
  $('.collapse-toggle').on('click', (e) => collapseMenu(e)); 

  $('#js-header-dropdown').on('click', function(e) {
    if (window.innerWidth > 1132) return;

    e.preventDefault();
    $($(this).data('target')).toggleClass('show');
  });

  // $('.js-collapse').on('click', '.faq-card__question', function(e) {
  //   var $wrap = $(this).closest('.faq-card')
  //   $wrap.toggleClass('active')
  //   var $answer = $wrap.find('.faq-card__answer')
  //   $answer.slideToggle()
  // });

  if (document.querySelector('.swiper-bottom-mini-banners')) {
    var swiper = new Swiper('.swiper-bottom-mini-banners', {
      observer: true,
      observeParents: true,
      loop: false,
      speed: 700,
      updateOnWindowResize: true,
      slidesPerView: "auto",
      lazy: true,
      breakpoints: {
        1400: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1100: {
          slidesPerView: "auto",
          spaceBetween: 0,
        },
        320: {
          slidesPerView: "auto",
          spaceBetween: 0,
        },
      },
      autoplay: {
       delay: 3000,
      },
    })
  }

  if (document.querySelector('.swiper-mini-banners')) {
    var swiper = new Swiper('.swiper-mini-banners', {
      loop: false,
      speed: 700,
      updateOnWindowResize: true,
      slidesPerView: "auto",
      effect: 'fade',
      autoplay: {
       delay: 3000,
      },
    })
  }

  if (document.querySelector('.swiper-banners')) {
    new Swiper('.swiper-banners', {
      loop: false,
      speed: 700,
      slidesPerView: 'auto',
      updateOnWindowResize: true,
      effect: 'fade',
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      autoplay: {
        delay: 3000,
      },
    })
  }

  Inputmask({alias: 'email', 'showMaskOnHover': false}).mask('input[role=email]');
  Inputmask({mask: '+7(999)999-99-99', 'showMaskOnHover': false}).mask('input[role=phone]');

  $('form #accept').on('change', function() {
    $(this).closest('form').find('input[type=submit]').prop('disabled', !$(this).is(':checked'))
  })

  new AirDatepicker('.datepicker');

  $('.js-anchor').on('click', function(e) {
    const $slideTo = $($(this).attr('href').slice(1) + '-anchor')

    if ($slideTo.length) {
      closeNav();
      $('html, body').animate({scrollTop:  $slideTo.offset().top}, 500)
    }

  })

  if(window.location.hash) {
    const $slideTo = $(window.location.hash + '-anchor')

    if ($slideTo.length) {
      $('html, body').animate({scrollTop:  $slideTo.offset().top}, 500)
    }
  }

  new Plyr('#player', {settings: [], controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']});

  $('.ellipsis').each(function(i, el) {
    new Ellipsis(el).calc().set();
  })
  $('.like').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation()
    var article = $(this).data('article')
    var like = $(this).data('like')
    var target = $(this).data('target')
    var likeElements = $("[data-like='" + like +"']")
    var targetElements = $("[data-count='" + target +"']")
    console.log(targetElements)
    if (likeElements.hasClass('active')) {
      likeElements.removeClass('active')
      $.post('/articles/'+ article + '/like', { add: 0 }, function(data) {
        targetElements.html(data)
      })
    } else {
      likeElements.addClass('active')
      $.post('/articles/'+ article + '/like', { add: 1 }, function(data) {
        targetElements.html(data)
      })
    }
  })

  $('.search').on('click', function(e) {
    e.preventDefault();
  });
  var delay = (function(){
    var timer = 0;
    return function(callback, ms){
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
  })();
  $('.search #specializations_search').on('keyup', function() {
    var $this = $(this);
    if ($this.val().length) {
      $('.icon-reset').show()
    } else {
      $('.icon-reset').hide()
    }
    delay(function() {
      $('body').addClass('wait');
      $('.accordion').html('');
      $.ajax({
        url: window.location,
        data: '&search='+$this.val(),
        dataType: 'script',
      }).done(function() {
        $this.attr('data-empty', !$this.val())
        $('body').removeClass('wait');
      });
    }, 400);
  });
    $('.search').on('reset', function() {
    var $search_field = $('#specializations_search');
    $search_field.val('');
    $search_field.attr('data-empty', true);
  });
  $('.icon-reset').on('click', function() {
    $('.search').trigger('reset');
    $('#specializations_search').trigger('keyup');
    $('.icon-reset').hide()
  });
})
